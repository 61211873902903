/* Preloader animation */
.preloader {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    z-index: 9999999;
}

.preloader.hide {
    top: -100%;
    transition: all 0.3s ease-in-out;
}